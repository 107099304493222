body {
  background-color: #eee;
  font-family: 'Roboto', sans-serif;
  transition: 0.3s;
}

/* HEADER STYLES */

.header {
  /* z-index: 2; */
  position: fixed;
  left: 0;
  top: 0;
  background-color: #ddd;
  width: 100%;
  padding: 5px;
  display: flex;
  justify-content: space-between;
  transition: 0.3s;
}

.darkHeader {
  /* z-index: 2; */
  position: fixed;
  left: 0;
  top: 0;
  background-color: #111;
  width: 100%;
  padding: 5px;
  display: flex;
  justify-content: space-between;
  transition: 0.3s;
}

.headerRight {
  display: flex;
  align-items: center;
}

.displayName {
  font-size: 12px;
  margin-right: 5px;
}

.darkDisplayName {
  color: white;
  font-size: 12px;
  margin-right: 5px;
  transition: 0.3s;
}

.sideNav {
  height: 100%;
  display: none;
  width: 0;
  position: fixed;
  z-index: 3;
  top: 0;
  right: 0;
  background-color: #bbb;
  overflow-x: hidden;
  transition: 0.3s;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.darkSideNav {
  height: 100%;
  display: none;
  width: 0;
  position: fixed;
  z-index: 3;
  top: 0;
  right: 0;
  background-color: #444;
  overflow-x: hidden;
  transition: 0.3s;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.openNav {
  width: 200px;
  transition: 0.3s;
}

.closebtn {
  text-align: center;
  width: 100%;
  padding: 10px;
  border: none;
  border-bottom: 1px solid #aaa;
  background-color: #ddd;
}

.darkClosebtn {
  text-align: center;
  width: 100%;
  padding: 10px;
  border: none;
  border-bottom: 1px solid #555;
  background-color: #333;
}

.closebtn img, .darkClosebtn img {
  height: 20px;
  width: auto;
}

.nothing {
  display: none;
  transition: 0.3s;
}

@media screen and (max-height: 450px) {
  .sidenav {padding-top: 15px;}
  .sidenav a {font-size: 18px;}
}

.sidebarButton {
  padding: 0 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  border-radius: 5px;
  background-color: #ddd;
  margin-left: 5px;
}

.darkSideBarButton {
  padding: 0 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  border-radius: 5px;
  margin-left: 5px;
  background-color: #222;
}

.sidebarButton img, .darkSideBarButton img {
  height: 25px;
  width: auto;
}

.sideNavButton {
  padding: 10px;
  border: none;
  border-bottom: 1px solid #aaa;
  background-color: #ccc;
  display: flex;
  justify-content: center;
  align-items: center;
}

.darkSideNavButton {
  padding: 10px;
  border: none;
  border-bottom: 1px solid #555;
  background-color: #222;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sideNavButton img, .activeSideButton img, .darkActiveSideButton img, .darkSideNavButton img, .activeSideNavButton img {
  height: 15px;
  width: auto;
  margin: 0 5px;
}

.activeSideNavButton {
  padding: 10px;
  border: none;
  border-bottom: 1px solid #aaa;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #eee;
  transition: 0.3s;
}

.darkActiveSideButton {
  padding: 10px;
  border: none;
  border-bottom: 1px solid #444;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #555;
  color: white;
  transition: 0.3s;
}

.darknessStyle {
  background: rgba(0,0,0,0.4);
  height: 100vh;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 2;
  transition: 0.3s;
}

/* FOOTER STYLES */

.footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: #ddd;
  color: #000;
  text-align: center;
  font-size: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.darkFooter {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: #111;
  color: white;
  text-align: center;
  font-size: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.footer a, .darkFooter a {
  padding: 14px 0;
  width: 20%;
}

.navLink, .navLink:active, .navLink:visited, .navLink:focus {
  color: #000;
  text-decoration: none;
}

.darkNavLink, .darkNavLink:active, .darkNavLink:visited, .darkNavLink:focus {
  color: white;
  text-decoration: none;
}

.navActive {
  background-color: #bbb;
}

.darkNavActive {
  background-color: #333;
}

.footer img, .darkFooter img {
  height: 20px;
  width: auto;
}

/* SIGNIN STYLES */

.signin {
  width: 80%;
  margin: auto;
  text-align: center;
  padding-top: 20vh;
}

.signinForm {
  background-color: white;
  display: flex;
  flex-direction: column;
  padding: 10px;
  margin-top: 20px;
  border-radius: 5px;
  border: 1px solid black;
  box-shadow: 0 3px 5px rgba(0,0,0,0.2);
}

.signinForm p {
  font-size: 14px;
}

input {
  width: 100%;
  border-radius: 5px;
  padding: 5px;
  box-sizing: border-box;
  margin: 5px 0;
  border-top: 2px solid #999;
  border-left: 2px solid #999;
  border-right: 2px solid #ddd;
  border-bottom: 2px solid #ddd;
}

.errorMessage {
  color: red;
  font-size: 12px;
}

.submitButton {
  border-radius: 5px;
  padding: 5px;
  font-size: 17px;
  margin: 5px 0;
  width: 100%;
  background-color: #ddd;
  border-top: 2px solid #ddd;
  border-left: 2px solid #ddd;
  border-right: 2px solid #999;
  border-bottom: 2px solid #999;

}

.logo {
  display: flex;
  justify-content: center;
  align-items: center;
}

.logoPic {
  border-radius: 50%;
  height: 50px;
  width: auto;
  margin: 0 7px;
}

.logoTitle {
  margin: 0 7px;
  font-size: 30px;
  font-family: 'Roboto Mono', monospace;
}

/* NEW POST FORM STYLES */

.newPostFormDiv {
  width: 100%;
  padding: 75px 10vw;
  margin: auto;
  text-align: center;
}

textarea {
  width: 100%;
  border-radius: 5px;
  padding: 5px;
  box-sizing: border-box;
  margin: 5px 0;
}

/* FEED STYLES */

.post {
  width: 85%;
  margin: 7px auto;
  padding: 10px 10px 6px 10px;
  border: 1px solid black;
  background-color: white;
  box-shadow: 0 2px 3px rgba(0,0,0,0.2);
  border-radius: 5px;
}

.darkPost {
  width: 85%;
  margin: 7px auto;
  padding: 10px;
  border: 1px solid white;
  background-color: #444;
  color: #ddd;
  box-shadow: 0 2px 3px rgba(255, 255, 255, 0.4);
  border-radius: 5px;
}

.postFeed {
  height: 100%;
}

.postsDiv {
  padding-top: 40px;
  height: 100%;
  padding-bottom: 60px;
}

.darkPostsDiv {
  padding-top: 40px;
  height: 100%;
  padding-bottom: 60px;
  background-color: #000;
}

.githubProfile h4 {
  margin-bottom: 10px;
  text-decoration: underline;
}

.githubProfileHeader {
  display: flex;
  align-items: center;
}

.gitHubProfileImgDiv {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  overflow: hidden;
  position: relative;
  border: 2px solid black;
  margin: 0 20px;
}

.gitHubProfileImgDiv img {
  height: 50px;
  position: absolute;
  top: -9999px;
  bottom: -9999px;
  left: -9999px;
  right: -9999px;
  margin: auto;
}

.githubStats {
  display: flex;
  justify-content: flex-start;
}

.stat {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid black;
  border-radius: 5px;
  padding: 5px;
  font-size: 12px;
  background-color: rgb(188, 225, 255);
  margin: 0 5px 5px 0;
}

.githubBodyInfo {
  font-size: 12px;
  margin-bottom: 5px;
}

.profilePosts {
  padding-bottom: 20px;
}

.upvoteDiv {
  height: 25px;
  width: 24px;
  border: 1px solid black;
  border-radius: 5px;
  margin-right: 10px;
  float: left;
}

.darkUpvoteDiv {
  height: 25px;
  width: 24px;
  border: 1px solid white;
  border-radius: 5px;
  margin-right: 10px;
  float: left;
}

.clickedUpvoteDiv {
  background-color: rgb(255, 216, 166);
  height: 25px;
  width: 24px;
  border: 1px solid black;
  border-radius: 5px;
  margin-right: 10px;
  float: left;
}

.darkClickedUpvoteDiv {
  background-color: rgb(216, 139, 38);
  height: 25px;
  width: 24px;
  border: 1px solid white;
  border-radius: 5px;
  margin-right: 10px;
  float: left;
}

.upvoteDiv img, .clickedUpvoteDiv img, .darkUpvoteDiv img, .darkClickedUpvoteDiv img {
  height: 16px;
  margin: 3px 0 0 3px;
  width: auto;
}

.postDeleteButton {
  border: 1px solid black;
  background-color: rgb(255, 190, 190);
  padding: 4px 7px;
  border-radius: 5px;
  font-weight: bold;
  display: flex;
  font-size: 14px;
  height: 25px;
  width: 25px;
  float: right;
  margin-left: 10px;
}

hr {
  margin: 5px 0;
}

.tags {
  display: flex;
  flex-wrap: wrap;
}

.tag {
  padding: 4px;
  background-color: rgb(188, 225, 255);
  font-size: 14px;
  border: 1px solid black;
  border-radius: 5px;
  margin: 0 2px 4px 2px;
}

.darkTag {
  padding: 4px;
  background-color: rgb(20, 77, 163);
  font-size: 14px;
  border: 1px solid white;
  border-radius: 5px;
  margin: 0 2px 0px 2px;
}

.tagAuthorRow {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.postAuthor {
  margin-left: 5px;
  font-size: 14px;
  color: rgb(0, 102, 255);
}

.darkPostAuthor {
  margin-left: 5px;
  font-size: 14px;
  color: rgb(0, 225, 255);
}

.postDescription {
  margin-top: 5px;
  border: 1px solid #aaa;
  border-radius: 3px;
  padding: 5px;
  font-size: 14px;
}

.commentLink {
  font-size: 12px;
  color: blue;
  margin: 5px;
  text-align: right;
}

.detailsBackButton {
  margin-top: 35px;
  padding: 5px;
  border: none;
  width: 100%;
  background-color: #ccc;
  text-align: left;
}

.detailsBackButton img {
  height: 25px;
  width: auto;
}

.filterTag {
  width: 100%;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
}

.clearButton {
  padding: 5px;
}

.filterTag button {
  height: 25px;
  border: 1px solid black;
  border-radius: 5px;
  background-color: rgb(255, 190, 190);
}

.filterTagHeader {
  display: flex;
  align-items: center;
}

.filterTagHeader p {
  margin-right: 5px;
}

.activeSaved {
  height: 26px;
  /* width: 25px; */
  background-color: rgb(205, 255, 147);
  border: 1px solid black;
  border-radius: 5px;
  float: right;
  margin: 0 0 0 10px;
}

.inactiveSaved {
  height: 26px;
  /* width: 25px; */
  border: 1px solid black;
  border-radius: 5px;
  background-color: white;
  float: right;
  margin: 0 0 0 10px;
}

.activeSaved img, .inactiveSaved img {
  height: 18px;
  width: auto;
  margin: 3px;
  margin-bottom: 0;
}

/* PROFILE STYLES */

.profileBackground {
  background-color: #aaa;
  padding-top: 10px;
  min-height: 100vh;
  padding-bottom: 70px;
}

.profile {
  border: 1px solid black;
  border-radius: 5px;
  margin: 35px 10px 10px 10px;
  background-color: #eee;
}

.profileTop {
  display: flex;
  align-items: center;
  padding: 10px;
  padding-bottom: 0;
  margin-bottom: 10px;
}

.profileImgDiv {
  height: 60px;
  width: 60px;
  border-radius: 50%;
  overflow: hidden;
  position: relative;
  border: 2px solid black;
  margin: 0 20px;
}

.profileImgDiv p {
  margin-top: 14px;
  font-size: 12px;
  text-align: center;
  color: blue;
}

.profileTop img {
  height: 60px;
  position: absolute;
  top: -9999px;
  bottom: -9999px;
  left: -9999px;
  right: -9999px;
  margin: auto;
}

.followList {
  padding-top: 35px;
}

.activeFollowButton {
  background-color: rgb(188, 225, 255);
  border-radius: 5px;
  padding: 2px;
  float: right;
  margin: 5px;
}

.inactiveFollowButton {
  background-color: white;
  border-radius: 5px;
  padding: 2px;
  float: right;
  margin: 5px;
}

.topPostTitle {
  margin-left: 10px;
}

.gitHubData {
  padding: 10px;
  border: 1px solid black;
  border-radius: 5px;
  margin: 10px;
  background-color: #ccc;
}

.commentForm {
  display: flex;
  align-items: center;
}

.commentForm input {
  margin: 0;
}

.commentForm button {
  border-radius: 5px;
  padding: 5px 3px;
  margin-left: 4px;
}

.comment {
  margin: 5px 0;
  padding: 5px;
  border-radius: 5px;
  background-color: #eee;
  /* display: flex; */
}

.commentBody {
  font-size: 12px;
  margin-bottom: 3px;
}

.commentHeader {
  display: flex;
  margin-bottom: 5px;
}

.commentFooter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
  margin: 0;
}

.commentReplyButton {
  padding: 1px 3px;
  font-size: 10px;
  margin-right: 5px;
  margin-left: 25px;
  color: blue;
  border: none;
  background-color: transparent;
}

.commentAuthor {
  font-weight: bold;
}

.commentUpvoteDiv {
  height: 18px;
  width: 18px;
  border: 1px solid black;
  border-radius: 5px;
  margin-right: 10px;
  background-color: white;
}

.clickedCommentUpvoteDiv {
  background-color: rgb(255, 216, 166);
  height: 18px;
  width: 18px;
  border: 1px solid black;
  border-radius: 5px;
  margin-right: 10px;
}

.commentUpvoteDiv img, .clickedCommentUpvoteDiv img {
  height: 10px;
  margin: 0 3px 2px 3px;
  width: auto;
}

.commentReplyForm {
  display: flex;
  align-items: center;
}

.commentReplyForm input {
  font-size: 12px;
  padding: 2px;
}

.commentReplySubmit {
  border-radius: 5px;
  padding: 2px;
  font-size: 12px;
  margin-left: 3px;
}

.reply {
  background-color: #ddd;
  padding: 5px;
  font-size: 12px;
  margin: 4px 0;
  border-radius: 5px;
}

.reply .commentAuthor {
  text-align: right;
  font-size: 10px;
}

.followPanel {
  display: flex;
  align-items: center;
}

.followProfilePicDiv {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  overflow: hidden;
  position: relative;
  border: 2px solid black;
  margin: 0 15px 0 10px;
}

.followProfilePicDiv img {
  height: 40px;
  position: absolute;
  top: -9999px;
  bottom: -9999px;
  left: -9999px;
  right: -9999px;
  margin: auto;
}

/* LANDING PAGE STYLES */

.landingPage {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 15vh 14vw 20vh 14vw;
  width: 100vw;
  height: 100vh;
  /* z-index: 2; */
  position: fixed;
  top: 0;
  left: 0;
}

.landingPage a {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  color: black;
  text-decoration: none;
}

.landingPage a:active, .landingPage a:hover {
  color: black;
  text-decoration: none;
}

.landingPage a:hover, .landingPage button:hover {
  cursor: pointer;
}

.link {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid black;
  border-radius: 5px;
  margin: auto;
  background-color: white;
  box-shadow: 0 2px 3px rgba(0,0,0,0.2);
  font-size: 12px;
  margin: 5px;
  width: 80%;
}

.link img {
  height: 18px;
  width: auto;
  margin-right: 10px;
}

.logoImg {
  height: 80px;
  width: auto;
  border-radius: 50%;
}

.logoText {
  font-size: 55px;
  margin-top: 5px;
  color: black;
  text-shadow: -4px -4px 2px #eee, 4px -4px 2px #eee, -4px 4px 2px #eee, 4px 4px 2px #eee;
}

.enterAppButton {
  border: 1px solid black;
  border-radius: 5px;
  padding: 10px;
  margin: 5px auto;
  background-color: rgb(97, 241, 92);
  box-shadow: 0 2px 3px rgba(0,0,0,0.2);
  font-size: 18px;
  width: 80%;
}

.copyright {
  font-size: 12px;
  margin: 5px 0 20px 0;
}

.behindModal {
  position: fixed;
  top: 0;
  left: 0;
  /* z-index: 2; */
  width: 100vw;
  height: 100vh;
  background-color: rgba(0,0,0,0.5);
  padding-top: 20vh;
}

.modal {
  background-color: #eee;
  border: 1px solid black;
  border-radius: 5px;
  padding: 10px;
  width: 80%;
  height: 200px;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.activityDataVis, .languageDataVis {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 10px 0;
  border: 1px solid black;
  border-radius: 5px;
  padding: 5px 5px 5px 0;
  box-shadow: 0 2px 4px rgba(0,0,0,0.2);
  background-color: #eee;
}

.activityDataVis h4, .languageDataVis h4 {
  text-align: center;
  text-decoration: none;
}

.linkGithubForm {
  width: 90%;
  margin: auto;
  border: 1px solid black;
  border-radius: 5px;
  padding: 5px;
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}

.linkGithubForm button {
  padding: 5px;
  border-radius: 5px;
}

.githubGetButton {
  width: 46%;
  padding: 5px;
  margin: 5px 2%;
  border-radius: 5px;
  border: 1px solid black;
  background-color: rgb(119, 184, 76);
}

.githubGetButton:hover {
  opacity: 0.8;
  cursor: pointer;
}

button {
  border: 1px solid black;
}

.modal p {
  margin-top: 10px;
  margin-bottom: 5px;
}

.modalButton {
  width: 80%;
  margin: 2px auto; 
  padding: 10px;
  border-radius: 5px;
}

.confirmModalButton {
  background-color: rgb(180, 255, 158);
}

.backModalButton {
  background-color: rgb(255, 169, 169);
}

/* MEDIA QUERIES */

@Media only screen and (min-width: 600px) {
  .signinForm {
    width: 350px;
    margin-left: auto;
    margin-right: auto;
    padding: 15px;
  }
  .signinForm h2 {
    font-size: 35px;
  }
  .signinForm input {
    font-size: 17px;
  }
  .signinForm button {
    font-size: 20px;
  }
  .signinForm p {
    font-size: 18px;
  }
  .copyright {
    font-size: 20px;
  }
  .landingPage button {
    width: 300px;
    font-size: 25px;  
  }
  .landingPage .link {
    width: 300px;
    font-size: 18px;
    padding: 5px 10px;
  }
  .landingPage .link img {
    height: 30px;
    margin-right: 15px;
  }
  .post {
    width: 60%;
  }
  .post h4 {
    font-size: 20px;
  }
  .displayName {
    font-size: 16px;
  }
  .sidebarButton {
    background-color: #ccc;
  }
  .sidebarButton img {
    height: 30px;
  }
  .header {
    position: static;
    background-color: #ccc;
  }
  .footer {
    position: static;
    height: 53px;
  }
  .openNav {
    width: 300px;
  }
  .sideNavButton {
    font-size: 20px;
  }
  .sideNavButton img {
    height: 25px;
    margin-right: 10px;
  }
  .closebtn img {
    height: 30px;
  }
  .postDescription {
    font-size: 16px;
  }
  .detailsBackButton {
    margin-top: 10px;
  }
  .detailsBackButton img {
    height: 40px;
  }
  .newPostFormDiv {
    width: 75%;
  }
  .followProfilePicDiv  {
    height: 60px;
    width: 60px;
  }
  .followProfilePicDiv img  {
    height: 60px;
  }
  .followName {
    font-size: 20px;
  }
  .postsDiv {
    padding-top: 10px;
  }
  .profile {
    margin-top: 0;
  }
  .profileTop {
    padding: 20px 20px 10px 20px;
  }
  .profileTop h2 {
    font-size: 30px;
    margin-left: 10px;
  }
  .profileImgDiv {
    height: 100px;
    width: 100px;
  }
  .profileImgDiv img {
    height: 100px;
  }
  .githubProfile h4 {
    font-size: 30px;
  } 
  .gitHubProfileImgDiv {
    height: 80px;
    width: 80px;
  }
  .gitHubProfileImgDiv img {
    height: 80px;
  }
  .githubProfileHeader h3 {
    font-size: 25px;
  }
  .stat {
    font-size: 20px;
  }
  .githubBodyInfo {
    font-size: 18px;
  }
  .languageDataVis, .activityDataVis {
    width: 45%;
    margin: 20px auto;
  }
  .languageDataVis h4, .activityDataVis h4 {
    font-size: 20px;
  }
  .languageDataVis canvas, .activityDataVis canvas {
    margin: auto;
  }
  .githubDataVis {
    display: flex;
  }
  .topPostTitle {
    font-size: 30px;
  }
  .activeFollowButton, .inactiveFollowButton {
    font-size: 20px;
    padding: 10px;
    margin: 10px;
  }
}