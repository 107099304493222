body, html {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  background-color: #eee;
}

* {
  box-sizing: inherit;
  margin: 0;
  padding: 0;
}